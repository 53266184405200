<template>
  <div>
    <div class="btn-group">
      <div
        class="btn btn-default"
        @click.stop.prevent="onBtnUpload"
        :title="$t('upload')"
      >
        <i class="fa fa-upload"></i>
      </div>
      <div
        class="btn btn-default"
        v-if="value"
        @click.stop.prevent="onBtnDownload"
        :title="$t('download')"
      >
        <i class="fa fa-download"></i>
      </div>
      <div class="btn btn-default" @click="onBtnEdit" :title="$t('edit')">
        <i class="fa fa-pencil"></i>
      </div>
      <div
        class="btn btn-default text-red"
        v-if="value"
        @click="onBtnDelete"
        :title="$t('delete')"
      >
        <i class="fa fa-trash"></i>
      </div>
    </div>
    <Modal
      backdrop="static"
      :title="$t('titles.standard_curve')"
      :open.sync="modal"
      :footer="false"
      @hide="modal = false"
    >
      <template #content>
        <section v-if="modal">
          <section v-if="type == 'upload'">
            <ImportFileForm
              :entity="$t('titles.standard_curve')"
              :hasBasic="false"
              :downloadBaseFileFunction="downloadExample"
              @submit="onSave"
            />
          </section>
          <section v-if="type == 'edit'" class="editor">
            <FormKeyValue
              :noFocus="true"
              v-model="form"
              :defaultSelection="false"
              keyColumn="X"
              valueColumn="Y"
            >
            </FormKeyValue>
            <div style="position:fixed;right:40px;top:10px">
              <button
                class="btn btn-xs btn-primary "
                @click="onSaveLocalList"
                :title="$t('confirm')"
              >
                <i class="fa fa-check"></i>
              </button>
            </div>
          </section>
        </section>
      </template>
    </Modal>
    <a
      href="/static/common/examples/data_standard_curve.csv"
      download="data_standard_curve.csv"
      ref="download"
      style="display:none"
    />
  </div>
</template>

<script>
import {isEqual} from "lodash";
import ImportFileForm from "@/components/import-file-form.vue";
import Modal from "@/components/widgets/modal.vue";
import FormKeyValue from "@/components/form-key-value.vue";

const initialState = () => ({
  modal: false,
  busy: false,
  type: "upload",
  localList: null
});

export default {
  name: "StandardCurveUploadForm",
  props: {
    value: {
      type: Array,
      required: false,
      default: null
    },
    filename: {
      type: String,
      required: false,
      default: "data_standard_curve.csv"
    }
  },
  components: {
    ImportFileForm,
    Modal,
    FormKeyValue
  },
  data: initialState,
  computed: {
    form: {
      set(value) {
        let x, y;
        let lst = (value?.items || [])
          .map(({id, text}) => {
            x = parseFloat(this.$utils.trim(`${id}`.replace(/,/, ".")));
            y = parseFloat(this.$utils.trim(`${text}`.replace(/,/, ".")));
            return !isNaN(x) && !isNaN(y) ? [x, y] : null;
          })
          .filter((i) => (i ? true : false));
        if (!isEqual(this.value, lst)) {
          this.localList = lst;
        }
      },
      get() {
        return {
          name: "unknown",
          items: (this.localList || []).map((i) => ({
            id: i[0],
            text: i[1]
          }))
        };
      }
    }
  },
  methods: {
    resetState() {
      Object.entries(initialState()).forEach((i) => (this[i[0]] = i[1]));
    },
    onBtnUpload() {
      this.type = "upload";
      if (this?.value?.length) {
        this.$utils
          .confirm(this, this.$t("titles.overwrite_existing_file"))
          .then((ok) => {
            if (!ok) return;
            this.modal = true;
          });
      } else {
        this.modal = true;
      }
    },
    onBtnDownload() {
      let body = ["x;y"]
        .concat(this.value.map((i) => `${i[0]};${i[1]}`))
        .join("\r\n");
      this.$utils.download(body, "text/csv", this.filename);
    },
    onBtnEdit() {
      this.type = "edit";
      this.modal = true;
      this.localList = this.value;
    },
    onBtnDelete() {
      if (!this.value) return;
      this.$utils
        .confirmRemoval(this, {
          type: this.$tc("file", 1),
          item: {name: this.$t("titles.standard_curve")}
        })
        .then((ok) => {
          if (!ok) return;
          this.$emit("input", null);
          this.$utils.notifyUser(
            this,
            this.$t("you_have_deleted_n_items", {count: 1})
          );
          this.resetState();
        });
    },
    onFinish(err) {
      this.$utils.notifyUser(
        this,
        this.$t(
          err
            ? "sorry_we_could_not_perform_your_request"
            : "success_import_message"
        ),
        {
          type: err ? "error" : "success"
        }
      );
      this.resetState();
    },
    onSave(formData) {
      const file = formData && formData.get("file");
      if (!file || file.type != "text/csv") {
        this.resetState();
        this.onFinish(true);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        try {
          let lst = (e?.target?.result || "")
            .replace(/[;]/g, ",")
            .replace(/\r\n/g, "\n")
            .split("\n");
          if (lst.length >= 1) {
            let cols;
            lst = lst
              .map((row) => {
                cols = row
                  .split(",")
                  .map((v) =>
                    !isNaN(parseFloat(this.$utils.trim(v)))
                      ? parseFloat(this.$utils.trim(v))
                      : null
                  );
                return cols.some((i) => i === null) ? null : cols;
              })
              .filter((row) => row !== null);
            if (lst.length >= 1) {
              this.$emit("input", lst);
              this.onFinish();
              return;
            }
          }
        } catch (e) {}
        this.onFinish(true);
      });
      reader.addEventListener("error", (e) => {
        this.resetState();
        this.onFinish(true);
      });
      reader.readAsText(file, "UTF-8");
    },
    onSaveLocalList() {
      this.$emit("input", this.localList.length >= 1 ? this.localList : null);
      this.onFinish();
    },
    downloadExample() {
      this.$refs.download.click();
    }
  }
};
</script>

<style scoped>
body:not(.skin-dark) .btn-default {
  background-color: #fff;
}

.editor {
  position: relative;
  max-height: 60dvh;
  overflow: auto;
}
</style>
